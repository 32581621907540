
export default function Vision() {

  return (
    <div>
        {/* <!-- https://wordtohtml.net/ --> */}

        <hr></hr>
        <h1> 
          Proof of Purchases
        </h1>
        <hr></hr>

        <p>I intend to buy $MAC tokens at least once a month, in amounts that target approximately 10% annual returns. $MAC will follow a "backed, not pegged" model -- meaning this 10%/year is an approximate floor, and there is no ceiling.</p>

        <hr></hr>
        <h1> 
          How are the purchases funded?
        </h1>
        <hr></hr>

        <p>For now I'm using income from my job, and money from my investments. I work in tech, and I've been active in trading and financial engineering for 12 years including my years in college. I maintain a diversified personal portfolio of bonds, stocks, options, and crypto assets.</p>
        <p>Unlike many community projects, there is no fee in the code for $MAC tokens. This means, relative to tokens that collect revenues through sell fees, there is no incentive to encourage selling. The stable price and consistent appreciation should encourage holding.</p>

        <hr></hr>
        <h1> 
          Why?
        </h1>
        <hr></hr>

        <p>
          This started as a hobby project. No funding. No marketing budget. I'm interested in seeing if a DAO can develop around a steadily appreciating token. 
        </p>

        <p>
          I was also motivated by a whitepaper for Curve protocol, which presents liquidity pools as savings accounts.
          By that analogy, $MAC liquidity pools can be thought of as "savings accounts". 
          Where purchases of $MAC tokens are analogous to "deposits" into the liquidity pools, and $MAC tokens are analogous to rights to "withdraw" from the liquidity pools. 
          Instead of an interest rate, $MAC holders are rewarded with increasing "withdrawal allowances" per token as the price of $MAC increases.
        </p>

        <p>As $MAC demonstrates predictable appreciation at faster rates than other stable assets, it could attract liquidity away from other assets or protocols in this space that fail to compete with $MAC token's price stability and appreciation.</p>

        <p>As $MAC earns deeper liquidity, there will be opportunities to implement DeFi flywheels to funnel yield from other protocols into $MAC revenue streams that sustain or improve the baseline 10%/year appreciation.</p>

        <p>As this happens; an on-chain treasury can develop, this project could transition into a DAO, and $MAC token holders could vote on the use of DAO funds.</p>

        <p>These concepts will be explained in more detail in the frameworks tab later.</p>

        <hr></hr>
        <h1> 
          Why the name 'Mac Ventures'?
        </h1>
        <hr></hr>
        <p>As the liquidity available for "withdrawls" grows beyond amounts needed for consistent $MAC appreciation, I'd love start using some of the $MAC that I buy to provide venture funding for other projects that improve the DeFi ecosystem.</p>

        <p>These $MAC tokens could be used by new protocols to access liquidity in exchange for new revenue streams for $MAC holders.</p>

        {/* <p>The long term vision is to create a form of money that has faster purchasing power growth relative to inflation-adjusted USD, 
          and is more stable than most Tokens.</p>

        <p>I'll add metrics to compare $MAC against other tokens and other forms of money to this page. I'll also add descriptions of CeFi and DeFi frameworks that will help achieve this vision to the 'Frameworks' page.</p> */}


        {/* price vs FPI */}
        {/* <p>
          To be held as a store of value, $MAC tokens need to have non-declining purchasing power. An easy way to monitor this is as their exchange rate against USDC. 
        </p> */}

        {/* 
        <p>
          Later, this can be measured by the exchange rate of $MAC tokens tokens that are pegged to inflation-adjusted USD. 
          $MAC is a better store of value if the exchange rate between $MAC and inflation adjusted tokens favors $MAC over time.
        </p> 
        */}

        {/* Able to exchange to other tokens with little price impact (price stability, deep liquiudity) */}

        {/* <p>
          Longer term, the adoption of $MAC as a store of value could be measured by the amount of $MAC that has been held for long periods of time. 
          To achieve this long term, automated CeFi and DeFi mechanisms could be developed to sustain and improve the purchasing power of $MAC tokens.
        </p> */}

        {/* 
        <h2>Long Term Vision: Become A Unit of Account</h2>
        <p>To become a unit of account, $MAC tokens need to have price stability. This can be measured by variance in exchange rates. The purchasing power of $MAC can also be measured in a variance-adjusted way, similar to a Sharpe Ratio, Information Ratio, or Sortino Ratio. $MAC is a better unit of account if these metrics are better for $MAC vs other money.</p>
        <p>Price stability can be achieved by keeping liquidity deep relative to flows.</p>
        <h2>Long Term Vision: Become A Medium of Exchange</h2>
        <p>To become a medium of exchange, $MAC tokens need to be broadly accepted for goods and services. This can be measured by transaction volume between non-exchange wallets.</p>
        <p>There are many ways this could be achieved; $MAC liquidity could be paired with more digital goods, $MAC could be integrated into more DeFi dApps and other dApps, $MAC could be adopted by more CeFi institutions, $MAC could be used in a grant protocol, $MAC could be used in bug bounties, &hellip; .</p>
        <h2>Longer Term Vision: Become A Protocol with Minimal Governance</h2>
        <p>To become a protocol, the steps for achieving the vision outlined above can be automated through smart contracts. Where decisions need to be made, controllers with minimal governance could be derived.</p>
        <h2>Longer Term Vision: Governance by Direct Democracy</h2>
        <p>Many systems are governed in a capitalistic way where governance decisions are made by token-weighted votes. In the context of money, this can result in a system that is biased against people with less wealth. A less biased money could have direct democratic governance where each person&rsquo;s vote is weighted equally.</p>
        <h2>Ultimate Vision: Become An Immutable Protocol</h2>
        <p>As various functionalities for $MAC are tested and proven to facilitate the vision outlined above, these functions could be automated with immutable smart contracts. Over time this could result in an immutable protocol for the best money.</p>
        */}
    </div>
  )
}
