import { useCallback, useRef, useState } from 'react'
import { FiGlobe } from 'react-icons/fi'
import { SupportedLocale, SUPPORTED_LOCALES, SwapWidget } from '@uniswap/widgets'

// ↓↓↓ Don't forget to import the widgets' fonts! ↓↓↓
import '@uniswap/widgets/fonts.css'
// ↑↑↑

import { useActiveProvider } from '../connectors'
import { JSON_RPC_URL, } from '../constants_set_jsonrpc'
import Web3Connectors from './Web3Connectors'
import Embed from "./Embed"
// import Navbar from './Navbar'
import styles from '../styles/Home.module.css'

// const TOKEN_LIST = 'https://gateway.ipfs.io/ipns/tokens.uniswap.org'
const MAC = '0xf01baf83e0b1a66110188e0d36b41099c47008ee'

const TOKEN_LIST = [
  {
    "name": "Mac Ventures Token",
    "address": "0xf01baf83e0b1a66110188e0d36b41099c47008ee",
    "symbol": "MAC",
    "decimals": 18,
    "chainId": 1,
    "logoURI": "https://raw.githubusercontent.com/MacStrelioff/crypto_public/main/Logo%2032x32.png"
  },
  {
    "name": "Dai Stablecoin",
    "address": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    "symbol": "DAI",
    "decimals": 18,
    "chainId": 1,
    "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
  },
  {
    "name": "Tether USD",
    "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    "symbol": "USDT",
    "decimals": 6,
    "chainId": 1,
    "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png"
  },
  {
    "name": "USD Coin",
    "address": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    "symbol": "USDC",
    "decimals": 6,
    "chainId": 1,
    "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png"
  },
  {
    "name": "Frax",
    "address": "0x853d955acef822db058eb8505911ed77f175b99e",
    "symbol": "FRAX",
    "decimals": 18,
    "chainId": 1,
    "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png"
  },
  {
    "name": "TempleDAO",
    "address": "0x470ebf5f030ed85fc1ed4c2d36b9dd02e77cf1b7",
    "symbol": "TEMPLE",
    "decimals": 18,
    "chainId": 1,
    "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16052.png"
  },
  {
    "name": "Frax Price Index",
    "address": "0x5ca135cb8527d76e932f34b5145575f9d8cbe08e",
    "symbol": "FPI",
    "decimals": 18,
    "chainId": 1,
    "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19425.png"
  },
  {
    "name": "Liquity USD",
    "address": "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
    "symbol": "LUSD",
    "decimals": 18,
    "chainId": 1,
    "logoURI": "https://etherscan.io/token/images/liquitylusd_32.png"
  },
  {
    "name": "Boosted LUSD",
    "address": "0xB9D7DdDca9a4AC480991865EfEf82E01273F79C3",
    "symbol": "bLUSD",
    "decimals": 18,
    "chainId": 1,
    "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/22090.png"
  },
]

export default function Token() {
  // When a user clicks "Connect your wallet" in the SwapWidget, this callback focuses the connectors.
  const connectors = useRef<HTMLDivElement>(null)
  const focusConnectors = useCallback(() => connectors.current?.focus(), [])

  // The provider to pass to the SwapWidget.
  // This is a Web3Provider (from @ethersproject) supplied by @web3-react; see ./connectors.ts.
  const provider = useActiveProvider()

  // The locale to pass to the SwapWidget.
  // This is a value from the SUPPORTED_LOCALES exported by @uniswap/widgets.
  const [locale, setLocale] = useState<SupportedLocale>('en-US')
  const onSelectLocale = useCallback((e) => setLocale(e.target.value), [])
  
  return (
    // for changing locale
      <div>

        {/* <div className={styles.i18n}>
          <label style={{ display: 'flex' }}>
            <FiGlobe />
          </label>
          <select onChange={onSelectLocale}>
            {SUPPORTED_LOCALES.map((locale) => (
              <option key={locale} value={locale}>
                {locale}
              </option>
            ))}
          </select>
        </div>
         */}

        <main className={styles.main}>
          <hr></hr>
          <h1> 
            $MAC Token Appreciation
          </h1>
          <hr></hr>
          <p>
            $MAC tokens target minimum price appreciation of approximately 10%/year. See how they've performed in the chart below!
          </p>
          <div className={styles.embed}>
            <Embed src={"https://dune.com/embeds/1662936/2754792/832a9df7-e9a6-4815-9e33-af5a3c10694f"} height="500px" background="wheat" />
          </div>

          <hr></hr>
          <h1> 
            Exchange Other Tokens For $MAC
          </h1>
          <p className="container token-address">Token Address: <br/> 0xf01baf83e0b1a66110188e0d36b41099c47008ee</p>
          <hr></hr>

          <div className={styles.demo}>
            <div className={styles.widgetContainer}>
              <h1 className={styles.title}>Exchange for $MAC</h1>
              <SwapWidget className={styles.widget}
                // TODO: change slippage tolerance
                jsonRpcEndpoint={JSON_RPC_URL} // TODO: get a custom value, generate a new Alchemey one
                tokenList={TOKEN_LIST}
                provider={provider} // TODO: get a custom value
                locale={locale} // TODO: add back the locale selector
                onConnectWallet={focusConnectors}
                defaultInputTokenAddress="0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
                defaultInputAmount="100"
                defaultOutputTokenAddress={MAC} />
            </div>
            <div className={styles.connectors} ref={connectors} tabIndex={-1}>
              <h1 className={styles.title}>Connect Wallet</h1>
              <Web3Connectors />
            </div>
          </div>

          <hr></hr>
          <h1> 
            Monitor My Buys
          </h1>
          <hr></hr>
          <p>
            I buy $MAC tokens at least once a month to back the 10% annual return floor. My buys are shown in the chart below:
          </p>

          <div className={styles.embed}>
            <Embed src={"https://dune.com/embeds/1812759/2983440/39aa4ba6-7f5f-44c2-b543-c8f4f4d523a3"} height="500px" background="wheat" />
          </div>

        </main>
      </div>
  )
}
