// imports for navbar routing
import {Route, Routes} from "react-router-dom";

// import pages
import Navbar from './Navbar'
import Token from './Token'
import Vision from './Vision'
import Framework from './Framework'
import Apps from './Apps'

export default function App() {
  return (
    <>
    <Navbar />
    <div className="container">
        <Routes>
            <Route path='/' element={<Token />} />
            <Route path='/vision' element={<Vision />} />
            <Route path='/framework' element={<Framework />} />
            <Route path='/apps' element={<Apps />} />
        </Routes>
    </div>
</>
  )
}

// Hosting a react app;
// https://www.hostinger.com/tutorials/what-is-react#How_to_Deploy_a_React_Application_on_Hostinger
// https://stackoverflow.com/questions/68963307/how-to-deploy-create-react-app-to-hostinger

// JS
// https://www.youtube.com/watch?v=hdI2bqOjy3c

// React
// https://www.youtube.com/watch?v=w7ejDZ8SWv8
