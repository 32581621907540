import {useState } from 'react'
// imports from index in assets
import {close,MacVenturesLogo,menu,} from '../assets'
import {navLinks} from '../constants'
import {Link} from "react-router-dom";

import styles from '../styles/Navbar.module.css'

// TODO: resolve typescript issues with CustomLink in Navbar.tsx
// 

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const path = window.location.pathname;
  return (

    <nav className={styles.navbar}>
      <div className={styles.brand}>
        MAC VENTURES
      </div>
      {/* TODO: add pages and movement between them */}
      <div className={styles.links}>
        <ul>
            <li><a href = '/'>Token</a> </li>
            <li><a href = '/vision'>Vision</a> </li>
            <li><a href = '/framework'>Framework</a> </li>
            <li><a href = '/apps'>Apps</a> </li>

          {/* 
          {navLinks.map((nav,index) => (
            // <CustomLink href={`$nav.id`} key={nav.id}> nav.title </CustomLink>
            <li key={nav.id}
              // className={`font-poppins cursor-pointer text-[16px] ${index === 0 ? 'ml-5' : 'ml-10'} text-white`}
            >
              <a href={`$nav.id`}>
                {nav.title}
              </a>
            </li>
            ))} 
            */}

          {/* <CustomLink href="/">Token</CustomLink>
          <CustomLink href="#/Vision">Vision</CustomLink>
          <CustomLink href="/learn/landing.html">Learn</CustomLink>
          <CustomLink href="#/apps">Apps</CustomLink>  */}
         
        </ul>
      </div> 
    </nav>

    // <nav className="navbar">
    //   {/* for more customization, see tailwind */}
    //   {/* <img src={MacVenturesLogo} alt="m" className="w-[124px] h-[32px]"/> */}
    //   {/* sm and hidden for mobile nagbar */}
    //     <ul className='list-none sm:flex hidden justify-left items-center flex-1'>
    //     {navLinks.map((nav,index) => (
    //       <li
    //         key={nav.id}
    //         className={`font-poppins cursor-pointer text-[16px] ${index === 0 ? 'ml-5' : 'ml-10'} text-white`}
    //       >
    //         <a href={`#$nav.id`}>
    //           {nav.title}
    //         </a>
    //       </li>
    //     ))}
    //   </ul>

    //   {/* <div className='text-[20px] text-white justify-right'>
    //     <p>
    //       CONNECT WALLET
    //     </p> 
    //   </div> */}

    //   <div className='sm:hidden flex flex-1 justify-end items-center'>
    //     <img src = {toggle ? close : menu} 
    //     alt = "menu"
    //     className="w-[28px] h-[28px] object-contain"
    //     onClick={() => setToggle((prev)=> !prev)}
    //     />
    //     <div
    //     className={`${toggle ? 'flex' : 'hidden'} p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
    //     >
    //       <ul className='list-none flex flex-col justify-end items-center flex-1'>
    //         {navLinks.map((nav,index) => (
    //           <li
    //             key={nav.id}
    //             className={`font-poppins font-normal cursor-pointer text-[16px] ${index === navLinks.length - 1 ? 'mr-0' : 'mb-4'} text-white`}
    //           >
    //             <a href={`#$nav.id`}>
    //               {nav.title}
    //             </a>
    //           </li>
    //         ))}
    //     </ul>
    //     </div>
    //   </div>
    // </nav>
  )
}

function CustomLink(href: any, children: any) {
  const path = window.location.pathname;
  return(
    <li className={path == href ? "active" : ""}>
      <a href={href}>{children}</a>
    </li>
)
}

// function greeter(fn: (a: string) => void) {
//   fn("Hello, World");
// }

export default Navbar;

// resources
// Template for blog posts: https://www.youtube.com/watch?v=Aj7HLsJenVg
// Navbar CSS; https://www.youtube.com/watch?v=SLfhMt5OUPI&t=11s
// Has navbar at the end: https://www.youtube.com/watch?v=yfoY53QXEnI
// https://www.youtube.com/watch?v=gXLjWRteuWI

