import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'

import {BrowserRouter} from "react-router-dom";

// TODO:
// difference between reactDOM.render()
// vs reactDOM.createRoot(...)
// it may relate to custom link components in navbars that would make blogging easier
// https://www.youtube.com/watch?v=SLfhMt5OUPI&t=11s

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
